// @flow
import * as React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Grid } from '../Grid/Grid';
import { CloseButton } from './CloseButton';
import { BasicLabel } from '../Label/BasicLabel';
import { PrimaryButton, NormalButton } from '../Buttons/Button';
import { LoaderIcon } from '../CustomSVGIcons';
import { isMobile } from 'lib/deviceDetect';
import styles from './modal.module.css';
import Error from '../Error';

type Props = {
  title: string | React.Node,
  onClose: () => void,
  onSubmit?: () => any,
  onCancel?: () => any,
  submitDisabled?: boolean,
  submitLabel?: React.Node,
  cancelLabel?: React.Node,
  open?: boolean,
  size?: 'small' | 'medium' | 'large',
  loading?: boolean,
  position?: 'center',
  fullHeight?: boolean,
  titleHint?: React.Node,
  showTitle?: boolean,
  dividers?: boolean,
  cancelButtonLink?: string,
  backgroundColor?: string,
  spacing?: 'dense' | 'zero' | 'normal' | 'large',
  overflow?: 'auto' | 'visible',
  children: React.Node,
  submitColor?: string,
  cancelButtonPosition?: 'right' | 'left',
  footerError?: string,
};

const defaultTheme = createTheme();

const redTheme = createTheme({
  palette: {
    primary: {
      main: '#C52100',
    },
  },
});

const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#fafafa',
      },
    },
    MuiDialog: {
      root: {
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
      },

      paperFullWidth: {
        verticalAlign: 'top',
        overflow: 'hidden',
        margin: '96px auto 48px',
        backgroundColor: '#fafafa',

        '@media(max-width: 1024px)': {
          margin: '96px 48px 48px',
        },

        '@media(max-width: 768px)': {
          width: 'calc(100% - 16px)',
          margin: '8px auto',
        },
      },
    },
    MuiDialogContent: {
      root: {
        overflowX: 'hidden',
        position: 'relative',

        '@media(max-width: 768px)': {
          overflow: 'auto',
          overflowX: 'visible',
        },
      },

      dividers: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
        borderTop: '1px solid rgba(0, 0, 0, 0.3)',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 24px',

        [defaultTheme.breakpoints.up('sm')]: {
          padding: '8px 24px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '14px 24px',
        position: 'relative',
        textAlign: 'center',
        display: 'block',
        [defaultTheme.breakpoints.up('sm')]: {
          textAlign: 'left',
          padding: '24px 24px 20px',
        },
      },
    },
  },

  breakpoints: {
    values: {
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const Dialog = ({
  title,
  onClose,
  onSubmit,
  submitDisabled = false,
  submitLabel = '',
  onCancel,
  titleHint,
  cancelLabel,
  fullHeight = false,
  open = true,
  size = 'medium',
  loading = false,
  cancelButtonLink = '',
  backgroundColor = '',
  spacing = 'normal',
  overflow = 'auto',
  showTitle = true,
  position = '',
  dividers = true,
  footerError = '',
  children,
  submitColor = '',
  cancelButtonPosition = 'left',
}: Props): React.Node => {
  let spacingClassName = styles.normalSpacing;
  if ('dense' === spacing) {
    spacingClassName = styles.denseSpacing;
  } else if ('medium' === spacing) {
    spacingClassName = styles.mediumSpacing;
  } else if ('zero' === spacing) {
    spacingClassName = styles.zeroSpacing;
  } else if ('large' === spacing) {
    spacingClassName = styles.largeSpacing;
  }

  const contentClasses = [spacingClassName];

  let paperDefaultClassName = '';
  if ('small' === size) {
    paperDefaultClassName = styles.small;
  } else if ('large' === size) {
    paperDefaultClassName = styles.large;
  } else {
    paperDefaultClassName = styles.medium;
  }

  const paperClasses = [paperDefaultClassName];

  if ('center' === position) {
    paperClasses.push(styles.center);
  } else if ('white' === backgroundColor) {
    paperClasses.push(styles.whiteBg);
  }

  if (fullHeight) {
    paperClasses.push(styles.fullHeight);
    contentClasses.push(styles.contentFullHeight);
  }

  if ('pink' === backgroundColor) {
    contentClasses.push(styles.pinkBg);
  } else if ('white' === backgroundColor) {
    contentClasses.push(styles.whiteBg);
  } else if ('yellow' === backgroundColor) {
    contentClasses.push(styles.yellowBg);
  }

  if ('visible' === overflow) {
    contentClasses.push(styles.overflowVisible);
    paperClasses.push(styles.overflowVisible);
  }

  const cancelButton = () => (
    <Grid item sm="auto" xs={12}>
      {cancelLabel && (
        <div className={styles.button}>
          <NormalButton onClick={onCancel} href={cancelButtonLink}>
            {cancelLabel}
          </NormalButton>
        </div>
      )}
    </Grid>
  );

  const getFooter = () => (
    <DialogActions disableSpacing>
      <Grid
        container
        gutter="custom"
        spacing={isMobile() ? 1 : 3}
        justify={isMobile() ? 'center' : 'space-between'}
      >
        <Grid item sm xs={12}>
          <Grid
            container
            gutter="custom"
            spacing={0}
            justify={isMobile() ? 'center' : 'flex-start'}
          >
            {cancelButtonPosition === 'left' && cancelButton()}
          </Grid>
        </Grid>

        <Grid item sm xs={12} style={{ paddingRight: '0' }}>
          <Grid
            container
            gutter="custom"
            spacing={isMobile() ? 0 : 2}
            justify={isMobile() ? 'center' : 'flex-end'}
          >
            {cancelButtonPosition === 'right' && cancelButton()}
            <Grid item sm="auto" xs={12}>
              {onSubmit && (
                <div className={styles.button}>
                  <React.Fragment>
                    <MuiThemeProvider
                      theme={'red' === submitColor ? redTheme : theme}
                    >
                      <PrimaryButton
                        onClick={onSubmit}
                        disabled={submitDisabled}
                      >
                        {loading && <LoaderIcon />}
                        {submitLabel}
                      </PrimaryButton>
                    </MuiThemeProvider>
                  </React.Fragment>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        {!!footerError && (
          <Grid item sm={12} xs={12}>
            <div className={styles.errorSpacing}>
              <Error message={footerError} margin="zero" />
            </div>
          </Grid>
        )}
      </Grid>
    </DialogActions>
  );

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return false;
    }

    onClose();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MuiDialog
        open={open}
        name="dialog"
        onClose={handleClose}
        aria-labelledby="dialog"
        classes={{
          paper: paperClasses.join(' '),
        }}
        scroll="body"
        fullWidth
      >
        {showTitle && (
          <DialogTitle>
            <Grid
              container
              gutter="custom"
              spacing={0}
              alignItems={isMobile() ? 'flex-start' : 'center'}
            >
              <Grid item xs={10}>
                <BasicLabel label={title} hint={titleHint} />
              </Grid>
              <Grid item xs={2} className={'end-xs'}>
                <div className={styles.close}>
                  <CloseButton handleClose={onClose} />
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
        )}

        <DialogContent
          dividers={dividers}
          classes={{
            root: contentClasses.join(' '),
          }}
        >
          {children}
        </DialogContent>
        {(onSubmit || onCancel) && getFooter()}
      </MuiDialog>
    </MuiThemeProvider>
  );
};

export default Dialog;
