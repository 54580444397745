import { useState, useEffect, useCallback } from 'react';

import { getSiteConfig, getUserDetails, signInWithToken } from './service';
import { capitalizeFirstLetter } from './helpers';
import { useBlocker } from 'react-router';

export const useSetDocumentTitle = (siteName, pageName, id = null) => {
  let formName = ' - Editar';
  if (id === 'new') {
    formName = ' - Crear nueva';
  } else if (id === null) {
    formName = '';
  }

  useEffect(() => {
    const DocumentTitleText = `${
      pageName + formName
    } - Admin ${capitalizeFirstLetter(siteName)}`;
    document.title = DocumentTitleText;
  }, [siteName, pageName, formName]);
};

export const useFetchSiteData = (site = '') => {
  const [adminUrl, setAdminUrl] = useState('');
  const [siteUrl, setSiteUrl] = useState('');
  const [siteName, setSiteName] = useState('');
  const [errors, setErrors] = useState({
    hasError: false,
    errorStack: '',
  });
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const siteConfig = await getSiteConfig(site);
        setSiteUrl(siteConfig.site_url);
        setAdminUrl(siteConfig.admin_url);
        setSiteName(siteConfig.site_name);
      } catch (error) {
        setErrors({
          hasError: true,
          errorStack:
            '' !== site ? site : window.location.host + ' is not a valid host',
        });
      }
    };
    fetchSiteData();
  }, [site]);
  return [adminUrl, siteUrl, errors, siteName];
};

export const useFetchUserData = (siteName, adminUrl) => {
  const [errors, setErrors] = useState({
    hasError: false,
    errorStack: '',
  });
  const [user, setUser] = useState({
    userRole: '',
    currentUser: null,
    currentUserDisplayName: '',
  });
  const [config, setConfig] = useState({});

  useEffect(() => {
    const initUser = async () => {
      try {
        const userData = await getUserDetails(adminUrl);
        const userRole = userData['roles'][0];
        if (userData['token'] && userData['token'] !== '') {
          try {
            await signInWithToken(userData['token']);
          } catch (e) {
            console.log('In Layout.js', e.message);
          }
        }

        setUser({
          userRole,
          currentUser: userData['id'],
          currentUserDisplayName: userData['displayName'],
          username: userData.username,
          s3ApiKey: userData.s3ApiKey,
        });
        setConfig(userData.config);
      } catch (error) {
        if (error.message === 'NOT_LOGGED_IN') {
          setErrors({
            hasError: true,
            errorStack: error.message + '| Redirecting to login page...',
          });
          window.location.href = adminUrl;
        } else {
          console.log(error.message);
          setErrors({
            hasError: true,
            errorStack: error.stack,
          });
        }
      }
    };
    if (adminUrl !== '') {
      initUser();
    }
  }, [siteName, adminUrl]);

  return [user, errors, config];
};

export const useUnsavedFormWarning = (
  hasUnsavedChanges,
  enableBrowserDefaultWarning = true
) => {
  const [showMessage, setShowMessage] = useState(false);

  const blockCondition = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname,
    [hasUnsavedChanges]
  );

  const blockState = useBlocker(blockCondition);

  useEffect(() => {
    // side-effect to manage browser tab close or reload events
    const beforeUnloadHandler = (event) => {
      if (!enableBrowserDefaultWarning) {
        return;
      }

      if (!hasUnsavedChanges) {
        return;
      }
      event.preventDefault();
      event.returnValue = true;
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () =>
      window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, [hasUnsavedChanges, enableBrowserDefaultWarning]);

  useEffect(() => {
    // side-effect to manage browser back button and tab change
    if (blockState.state === 'blocked') {
      if (hasUnsavedChanges) {
        blockState.reset();
      } else {
        blockState.proceed();
      }
      setShowMessage(hasUnsavedChanges);
    }
  }, [blockState, hasUnsavedChanges]);

  return [blockState, showMessage, setShowMessage];
};
