import React, { lazy, Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  useLocation,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router';

import { Layout } from 'containers/Layout';
import NotFoundPage from 'components/NotFoundPage';
import NotAuthorized from 'components/NotAuthorized';

const CrossPost = lazy(() => import('./CrossPost'));
const Config = lazy(() => import('../components/Config'));
const PostVersionList = lazy(() => import('../components/PostVersionList'));
const DeepLinkingNews = lazy(() => import('containers/DeepLinkingNews'));
const VipLinks = lazy(() => import('containers/VipLinks'));
const EvergreenLinks = lazy(() => import('containers/EvergreenLinks'));
const Recipes = lazy(() => import('containers/Recipes'));
const ProductDashboard = lazy(() => import('containers/ProductDashboard'));
const ProductoLayout = lazy(() => import('containers/ProductoLayout'));
const VideoFooter = lazy(() => import('containers/VideoFooter'));
const StreamingList = lazy(() => import('containers/StreamingList'));
const SiteUsersListing = lazy(() => import('containers/SiteUsersListing'));
const StreamingLayout = lazy(() => import('containers/StreamingLayout'));
const AuthorsDashboard = lazy(() => import('containers/AuthorsDashboard'));
const UserProfile = lazy(() => import('containers/UserProfile'));
const Categories = lazy(() => import('containers/Categories'));
const CategoriesListing = lazy(() => import('containers/CategoriesListing'));
const Tags = lazy(() => import('containers/Tags'));
const TagsListing = lazy(() => import('containers/TagsListing'));
const VideosOnCover = lazy(() => import('containers/VideosOnCover'));
const VideosOnCoverListing = lazy(() =>
  import('containers/VideosOnCoverListing')
);
const AuthorsListing = lazy(() => import('containers/AuthorsListing'));
const BreakingTags = lazy(() => import('containers/BreakingTags'));
const BreakingTagsListing = lazy(() =>
  import('containers/BreakingTagsListing')
);
const SectionModule = lazy(() => import('containers/SectionModule'));
const BlogInfo = lazy(() => import('containers/BlogInfo'));
const RobotsText = lazy(() => import('containers/RobotsText'));
const AdsText = lazy(() => import('containers/AdsText'));
const ClubLinksLateralListing = lazy(() =>
  import('containers/ClubLinksLateralListing')
);
const ClubLinksLateral = lazy(() => import('containers/ClubLinksLateral'));

type Props = {
  visitorId: number,
};

export const Main = ({ visitorId }: Props) => {
  const PostVersionListWrapper = () => {
    const location = useLocation();
    const { pathname } = location;
    return <PostVersionList pathname={pathname} />;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="post/new" element={<Layout visitorId={visitorId} />} />
        <Route
          path="escribir/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <Layout visitorId={visitorId} />
            </DndProvider>
          }
        />
        <Route path="backup/*" element={<Layout visitorId={visitorId} />} />
        <Route
          path="publicar/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <Layout visitorId={visitorId} />
            </DndProvider>
          }
        />
        <Route path="difundir/*" element={<Layout visitorId={visitorId} />} />
        <Route
          path="producto/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <ProductoLayout />
            </DndProvider>
          }
        />
        <Route
          path="ficha/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <ProductoLayout />
            </DndProvider>
          }
        />
        <Route path="version/*" element={<PostVersionListWrapper />} />
        <Route path="streamings" element={<StreamingList />} />
        <Route path="siteusers" element={<SiteUsersListing />} />
        <Route
          path="streamings/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <StreamingLayout />
            </DndProvider>
          }
        />
        <Route path="videofooter" element={<VideoFooter />} />
        <Route path="deeplinkingnews/*" element={<DeepLinkingNews />} />
        <Route path="viplinks" element={<VipLinks />} />
        <Route path="evergreen" element={<EvergreenLinks />} />
        <Route path="recipes" element={<Recipes />} />
        <Route
          path="products"
          element={<ProductDashboard activeTab={'products'} />}
        />
        <Route
          path="families"
          element={<ProductDashboard activeTab={'families'} />}
        />
        <Route path="configs/*" element={<Config />} />
        <Route path="notAuthorized" element={<NotAuthorized />} />
        <Route
          path="users/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <AuthorsDashboard activeTab={'users'} />
            </DndProvider>
          }
        />
        <Route path="users" element={<AuthorsListing />} />
        <Route
          path="profile"
          element={
            <DndProvider backend={HTML5Backend}>
              <UserProfile />
            </DndProvider>
          }
        />
        <Route path="categories" element={<CategoriesListing />} />
        <Route path="categories/*" element={<Categories />} />
        <Route path="tags" element={<TagsListing />} />
        <Route path="tags/*" element={<Tags />} />
        <Route path="videos/*" element={<VideosOnCover />} />
        <Route path="videos" element={<VideosOnCoverListing />} />
        <Route
          path="cross_post"
          element={
            <DndProvider backend={HTML5Backend}>
              <CrossPost />
            </DndProvider>
          }
        />
        <Route
          path="cross_post/branded"
          element={
            <DndProvider backend={HTML5Backend}>
              <CrossPost isBranded />
            </DndProvider>
          }
        />
        <Route
          path="breakingtags"
          element={
            <DndProvider backend={HTML5Backend}>
              <BreakingTagsListing />
            </DndProvider>
          }
        />
        <Route
          path="breakingtags/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <BreakingTags />
            </DndProvider>
          }
        />
        <Route
          path="sectionmodules"
          element={
            <DndProvider backend={HTML5Backend}>
              <SectionModule />
            </DndProvider>
          }
        />
        <Route
          path="bloginfo"
          element={
            <DndProvider backend={HTML5Backend}>
              <BlogInfo />
            </DndProvider>
          }
        />
        <Route
          path="robotstext"
          element={
            <DndProvider backend={HTML5Backend}>
              <RobotsText />
            </DndProvider>
          }
        />
        <Route
          path="ads"
          element={
            <DndProvider backend={HTML5Backend}>
              <AdsText />
            </DndProvider>
          }
        />
        <Route
          path="clublinks"
          element={
            <DndProvider backend={HTML5Backend}>
              <ClubLinksLateralListing />
            </DndProvider>
          }
        />
        <Route
          path="clublinks/*"
          element={
            <DndProvider backend={HTML5Backend}>
              <ClubLinksLateral />
            </DndProvider>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </>
    )
  );
  return (
    <Suspense fallback={<div className="grid-wrapper">Loading...</div>}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
